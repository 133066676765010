<template>
  <div class="receiptBankCard">
    <van-nav-bar
      :title="$t('RECEIPTBANKCARD')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <!-- <van-cell center> -->
        <!-- <div slot="title">{{$t('TAKECARDNAME')}}</div>
        <div slot="default">{{Takename}}</div> -->
      <van-field :readonly="stopSub" :placeholder="$t('TAKECARDNAME')" v-model="Takename" :label="$t('TAKECARDNAME')" />
      <!-- </van-cell> -->
      <!-- <van-cell center>
        <div slot="title">{{$t('CONTACTPHONE')}}</div>
        <div slot="default">{{phone}}</div>
      </van-cell> -->
      <van-field :readonly="stopSub" :placeholder="$t('CONTACTPHONE')" v-model="phone" :label="$t('CONTACTPHONE')" />
    </div>
    <div class="container margintop">
      <van-cell @click="selectM" center>
        <div slot="title">{{$t('BANKNAME')}}</div>
        <div slot="default">{{bankNameValue}}</div>
        <van-icon color="#666" size="20" slot="right-icon" name="arrow-down" />
      </van-cell>
      <van-field :readonly="stopSub" :placeholder="$t('INPUTBANKCARDNUMBER')" v-model="bankCard" :label="$t('BANKCRAD')" />
    </div>
    <!-- 弹窗 -->
    <div v-if="selectMActive" class="selectM">
      <div class="item" @click="changeSelects(item)" :class="{ active: item.active}" v-for="(item, index) in bankNames" :key="index">{{item.name}}</div>
    </div>
    <div class="tips">{{$t('BANKCRADSELF')}}</div>
    <div class="subbox">
      <van-button :disabled="stopSub" @click="sub" class="sub" color="#759FED" type="primary" block>{{$t('SUBMIT')}}</van-button>
    </div>
  </div>
</template>.

<script>
import { getbank, getBankNameList, myInformation, addBank } from '@/api/my'
import './css/my.css'
import { Toast } from 'vant'
export default {
  name: 'receiptBankCard',
  data () {
    return {
      // 持卡人姓名
      Takename: '',
      // 持卡人身份证号码
      IDnum: '',
      // 手机号码
      phone: '',
      // 开户银行
      bankNames: [],
      // 开户银行value
      bankNameValue: '',
      // 银行卡号
      bankCard: null,
      // 弹窗
      selectMActive: false,
      stopSub: true
    }
  },
  methods: {
    async loadData () {
      const data1 = await getBankNameList()
      const arr = []
      for (const key in data1.data.data) {
        const obj = {}
        obj.name = data1.data.data[key]
        obj.active = false
        arr.push(obj)
        this.bankNames = arr
      }
      const { data } = await getbank()
      if (data.code === 200 && data.data != null) {
        this.bankNameValue = data.data.bankName
        this.Takename = data.data.bankReservedName
        this.phone = data.data.phone
        this.bankCard = data.data.bankCardNo
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    changeSelects (item) {
      for (const key in this.bankNames) {
        this.bankNames[key].active = false
      }
      item.active = true
      this.bankNameValue = item.name
      this.selectMActive = false
    },
    selectM () {
      this.selectMActive = !this.selectMActive
    },
    async sub () {
      if (this.bankCard && this.bankNameValue && this.Takename && this.phone) {
        const { data } = await addBank({
          bankCardNo: this.bankCard,
          bankName: this.bankNameValue,
          bankReservedName: this.Takename,
          phone: this.phone
        })
        if (data.code === 200) {
          Toast(this.$t('SUBMITSUCCEED'))
          this.stopSub = true
        }
        if (data.code === 500) {
          Toast(data.message)
        }
      } else {
        Toast(this.$t('FILLINALL'))
      }
    },
    async getmyInformation () {
      const { data } = await myInformation()
      // console.log(data.data.bank)
      if (data.data.bank) {
        this.stopSub = true
      } else {
        this.stopSub = false
      }
    }
  },
  created () {
    this.loadData()
    this.getmyInformation()
  }
}
</script>

<style scoped>
.img {
  width: 100%;
  height: 100%;
}
.del {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-indent: .5em;
}
.showimg {
  position: relative;
  width: 100%;
  height: 100%;
}
.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.container {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background-color: #fff;
}
.margintop {
  margin-top: 20px;
}
.selectM {
  z-index: 9999;
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  background: #fff;
  border: 1px solid #000;
}
.active {
  color: #fff;
  background-color: rgb(114, 208, 252);
}
.item {
  font-size: 18px;
  padding: 8px;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
</style>
